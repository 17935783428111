import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";

import App from "./App";
import store from "./store";
import './index.css';
import * as serviceWorker from './serviceWorker';

serviceWorker.register();
// serviceWorker.unregister();

render(
	<Provider store={store}><App /></Provider>,
	document.getElementById("root")
);

