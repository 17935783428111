/******************************************************************************
 * Login Component
 * version: 1.0.1, release: 07.09.2018
 * author: albert lang, albert@jumptomorrow.com
 * ----------------------------------------------------------------------------
 * description:
 * 
 * dependencies: none
 * 
 * todos:
 * 
 * links:
 * https://react.semantic-ui.com/
 *  
******************************************************************************/

import React, { Component } from 'react';
import { Form, Grid, Header, Image, Segment } from 'semantic-ui-react'


//============================================================================= Login Component
export default class Login extends Component {
	constructor(){
		super();

		global.login = this;
	}

	static defaultProps = {onLogon:null}

	// username: petar@jumptomorrow.com
	// password: test
	state = {name:'', pw:'', error:false, loading:false}


	handleChange = (e, { name, value }) => this.setState({ [name]: value })


	handleSubmit = async () => {
		if (this.state.loading) return null;

		const { name, pw } = this.state;

		if (!name && global.config.debug[pw]){		//redirect to admin page
			return window.location.href = global.config.debug[pw];
		}

		if (!name || !pw) return null;
		// if (!name || !pw) return this.setState({error:true});

		console.log('login ...', name, pw);
		this.setState({loading:true});

		let headers = {
			'content-type'	: 'application/json; charset=utf-8',
		}
		let body = JSON.stringify({
			"username" : name,
			"password" : pw
		})
		let b = {method:'POST', headers, body};
		let res = await fetch(global.profile.login, b);

		if (!res.ok){
			console.error('login error! ' + global.profile.login + '\n' + res);
			return this.setState({error:true, loading:false});
		}

		let data = await res.json();
		console.log('result:', data);

		if (data && data.user && data.user.token){		//success
			console.log('---user data loaded:', data);
			localStorage.setItem(global.profile.app + '.user', JSON.stringify(data.user));

			this.setState({error:false, loading:false});
			if (this.props.onLogon) this.props.onLogon(data.user);

		}else{				//fail
			console.log('---error on loading data!');
			console.error('login error! ' + data);
			return this.setState({error:true, loading:false});
		}
	}


	handleLogoff = async => {
		localStorage.clear();
		window.location.reload();
	}


	render(){
		let s = this.state;

		return (
			<Grid id='login-view' textAlign='center' style={{ height: '100%', margin:0 }} verticalAlign='middle'>
			<Grid.Column style={{ maxWidth:450 }}>
	
				<Segment textAlign='left'>
					<Header as='h2'>
						Anmeldung des Benutzers
						<Image src={global.profile.icon} />
					</Header>

					<Form size='large'>
						<Form.Field>
							<label>Benutzername:</label>
							<Form.Input
								fluid
								icon='user'
								iconPosition='left'
								placeholder='Benutzername'
								type='email'
								name='name'
								autoComplete='on'
								onChange={this.handleChange}
								error={s.error}
								value={s.name}
							/>
						</Form.Field>
	
						<Form.Field>
							<label>Passwort:</label>
							<Form.Input
								fluid
								icon='lock'
								iconPosition='left'
								placeholder='Password'
								type='password'
								name='pw'
								autoComplete='on'
								onChange={this.handleChange}
								error={s.error}
								value={s.pw}
							/>
						</Form.Field>
	
						{global.user?
							<div className='login-buttons'>
								<button onClick={e => this.handleSubmit()} className='ui fluid button large'>{s.loading? 'loading...': 'Login'}</button>
								{/* <button onClick={e => this.handleLogoff()} className='ui fluid button large'>Logoff</button> */}
								<button onClick={e => this.props.onCancel()} className="ui fluid grey button large">Abbrechen</button>
							</div>
							:
							<button onClick={e => this.handleSubmit()} className='ui fluid button large'>{s.loading? 'loading...': 'Login'}</button>
						}

						{s.error &&
							<div className='warning'>Benutzername oder Passwort falsch!</div>
						}
					</Form>
				</Segment>

				<div className='version-login'>{'VERSION: ' + global.config.version}</div>

			</Grid.Column>
			</Grid>
		)
	}
}


/*
import React from 'react'
import { Button, Form, Grid, Header, Image, Message, Segment, Checkbox } from 'semantic-ui-react'
import './App.scss';




const onLogin = () => {

}

const LoginForm = () => (
	<Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
		<Grid.Column style={{ maxWidth: 450 }}>

			<Segment textAlign='left'>
				<Header as='h2'>
					<Image src='assets/icon_sam.png' />
					Anmeldung des Benutzers
				</Header>
				<Form size='large' onSubmit={e => {
						console.log('submit');
					}}>
					<Form.Field>
						<label>Benutzername:</label>
						<Form.Input fluid icon='user' iconPosition='left' placeholder='Benutzername' />
					</Form.Field>

					<Form.Field>
						<label>Passwort:</label>
						<Form.Input
							fluid
							icon='lock'
							iconPosition='left'
							placeholder='Password'
							type='password'
						/>
					</Form.Field>

					<Button fluid type='submit' size='large' onClick={e => {
							onLogin();
							console.log('click');
						}}>
						Login
					</Button>
				</Form>
			</Segment>
		</Grid.Column>
	</Grid>
)


export default LoginForm
*/