/******************************************************************************
 * AppPage Component
 * version: 1.0.2, release: 21.04.2018
 * author: albert lang, albert@jumptomorrow.com
 * ----------------------------------------------------------------------------
 * description:
 * 
 * dependencies: none
 * 
 * todos:
 * 
 * // http://sam.jumptomorrow.tv/login?token=MTU2MTk3NDgzOA==

******************************************************************************/

import React, { Component } from 'react';
import './AppPage.scss';
// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { pdfjs, Document, Page } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// var orientation = require('o9n').orientation;


//============================================================================= Page Component
export default class AppPage extends Component {
	state = {
		url		: null,
		pdf		: null,
		pdfLoaded: false,
	}

	static defaultProps = {}


	componentDidMount(){
		global.page = this;
		this.setMode('shelf');
	}


	componentWillUnmount(){
		global.page = null;

		// clearAllBodyScrollLocks();
		clearTimeout(this.timeout);
		clearInterval(this.interval);
	}

	
	switchView(){
		console.log('switch');
		this.setMode(this.state.mode === 'cms'? 'shelf' : 'cms');
	}


	setMode(mode){
		let url;

		if (mode === 'shelf'){
			url = global.profile.shelf + '?device_type=' + global.deviceType;
			//url += '&box_id=' + global.info.shelf.id;		//old !!!

			url += '&shelf_id=' + global.info.shelf.id;
			url += '&box_id=' + global.info.shelf.box_id;
			url += '&version=' + global.version;

			let id = global.user.merchant_id;
			if (id) url += '&api=' + global.profile.app + '.' + id;
			else url += '&reset=0';

		}else{		//mode = cms
			url = global.profile.cms + global.user.token;
		}

		url += '&t=' + Math.floor(new Date().getTime() / 1000);

		console.log('---load page to iframe:', url);
		global.info.log.push('load iframe url: ' + url);
		
		global.setOrientation(mode);
		this.setState({mode, url});
	}


	onLogoff(){
		// localStorage.removeItem(global.profile.app + '.user');
		global.app.setState({loggedin:false, reset:1});
	}


	onIframeLoaded(){
		console.log('*** iframe loaded!');

		this.iframe.contentWindow.postMessage('hello', '*');

		this.timeout = setTimeout(e => {
			if (!this.iframe || !this.iframe.contentWindow) return;

			if (this.state.mode === 'cms'){
				console.log('> send hello.eva to iframe ...');
				this.iframe.contentWindow.postMessage('hello.eva', '*');
	
			}else{		//shelf
				if (global.isEva50){
					console.log('> send hello.eva5-app to iframe ...');
					this.iframe.contentWindow.postMessage('hello.eva5-app', '*');	//route to shelf-app
				}
			}
		}, 3000);

		if (this.props.onLoad) this.props.onLoad();
	}


	render(){
		let s = this.state;
		// console.log('!!! load url:', s.url);

		return (
			<div ref='page' className='page'>
				<div className='menubar'>
					<img src={'assets/bar_1.png'} alt='' />
					<div className='menubar-switch' onClick={e => this.switchView()}></div>
					<div className='menubar-logoff' onClick={e => this.onLogoff()}>Abmelden</div>

					<div className='version'>{'VERSION: ' + global.version.replace('|', ' | ')}</div>
				</div>

				<iframe
					ref={e => this.iframe = e}
					title='iframe'
					className='iframe'
					src={s.url}
					// sandbox='allow-modals allow-forms allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-top-navigation'
					onLoad={this.onIframeLoaded.bind(this)}
				/>

				{s.loading &&
					<div className='spinner' />
				}

				{s.pdf &&
					<div className='pdf-view'>
						<div className='pdf-headline'>
							<div className='pdf-close' onClick={e => {
								clearInterval(this.interval);
								this.setState({pdf:null, pdfLoaded:false})
							}}>PDF SCHLIEßEN</div>
							{/* <a href={s.pdf} download>PDF downloaden</a> */}
							{/* <div className='pdf-close' onClick={e => {}}>PDF downloaden</div> */}
						</div>

						{typeof s.pdf === 'string' &&
							// <Document
							// 	id='pdf-document'
							// 	file={s.pdf}
							// 	onLoadSuccess={e => {
							// 		console.log('pdf load complete!', e.numPages);
							// 		this.setState({'numPages':e.numPages, pdfLoaded:true})
							// 	}}
							// 	>
							// 		{Array.from(new Array(s.numPages), (el, index) =>
							// 			<Page
							// 				key={`page_${index + 1}`}
							// 				pageNumber={index + 1}
							// 			/>
							// 		)}
							// </Document>

							<iframe
								className='iframe'
								title='pdf-document'
								id='pdf-document'
								src={global.config.pdfViewer + '?pdf=' + s.pdf}
								onLoad={e => {
									// this.setState({pdfLoaded:true})
								}}
							/>
						}
						{/* {(s.pdf && !s.pdfLoaded) &&
							<div className='spinner'>{s.pdf === true?'Dokument wird gerendert ...' : 'Dokument wird geladen ...'}</div>
						} */}
					</div>
				}
			</div>
		)
	}
}
