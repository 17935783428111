/******************************************************************************
 * App Component
 * version: 1.0.2, release: 26.02.2018
 * author: albert lang, albert@jumptomorrow.com
 * ----------------------------------------------------------------------------
 * description:
 * 
 * dependencies: none
 * 
 * todos:
 * 
******************************************************************************/

import React, { Component, version } from 'react';
import './App.scss';
import LoginForm from './Login';
import AppPage from './AppPage';


//----------------------------------------------------------------------------- getUrlVars
global.urlVars = {};
window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m,key,value) => {global.urlVars[key] = value});


global.setOrientation = mode => {
	if (!mode) mode = global.mode || 'shelf';
	global.info.mode = global.mode = mode;

	console.log('---mode:', mode);

	let app = document.getElementById('app');
	let rotated = window.orientation && window.orientation % 180 !== 0;

	if (!rotated || mode !== 'shelf'){
		app.style.transform = 'none';
		app.style.width = '100vw';
		app.style.height = '100vh';

	}else{
		// setTimeout(e => {
			// let h = app.clientHeight;
			let h = global.height;
			app.style.transform = 'translate(0, ' + h + 'px) rotate(-90deg)';
			app.style.width = '100vh';
			app.style.height = '100vw';
		// }, 500)
	}
}



//============================================================================= App Component
export default class App extends Component {

	state = {
		loaded		: false,
		loggedin	: false,
		reset		: 0,
		error		: '',
		shelf		: null,
		// rotated		: window.orientation % 180 !== 0,
		message		: 'LOADING...\n' + window.location.href,
		version		: '---',
	};

	static defaultProps = {}


	async componentDidMount(){
		let app = document.getElementById('root');
		global.height = window.orientation % 180 === 0? app.clientWidth : app.clientHeight;

		window.onorientationchange = e => global.setOrientation();

		console.log('---window.location:', window.location);

		global.app = this;
		global.user = {};

		console.log('load config ...');
		global.config = await ( await fetch('config.json') ).json();

		if (!global.config){
			return this.setState({message:'error on loading config.json!'});
		}else{
			this.setState({version:'version: ' + global.config.version});
		}
		
		console.log('load profiles ...');
		global.profile = await ( await fetch('profile.json') ).json();

		if (!global.profile){
			return this.setState({message:'error on loading profile.json!'});
		}

		console.log('load user data:', global.profile.app + '.user');
		let user = localStorage.getItem(global.profile.app + '.user');

		console.log('user:', user);

		global.info = {
			log			: [],
			version		: global.config.version,
			location	: window.location,
			urlVars		: global.urlVars,
			config		: global.config,
			profile		: global.profile,
			user		: user,
			deviceId	: null,
			deviceType	: null,
			shelf		: null,
			mode		: null,
		}

		this.messageBind = this.onMessage.bind(this);
		window.addEventListener("message", this.messageBind, false);	//android-child, ios-parent/child
		document.addEventListener("message", this.messageBind, false);	//android-parent

		if (user) this.onLogon(JSON.parse(user));		//-> onLogon
		else this.setState({loaded:true});		//-> login
	}

	
	onMessage(e){
// return this.setState({pdf:'Testumgebung - Merkzettel  - 20200907-144536.pdf', pdfLoaded:true});
		let data = e.data;
		console.log('> admin-app: message from iframe:', e);
		// console.log('> admin-app: message from iframe:', data.screenshot? 'screenshot' : data, e);

		// if (typeof data === 'string' && data.startsWith('screenshot')){
		// 	global.info.log.push('> ' + data);
		// }
// alert(data);
		if (data === 'hello.web-shelf'){;	//from child shelf-app (android, ios, win)
			//send to ios-app | win-app
			if (global.isEva50 && window.ReactNativeWebView){
				window.ReactNativeWebView.postMessage('hello.eva-app');
			}else{
				window.parent.postMessage('hello.eva-app', '*');		//android, ios, win, to parent iframe
			}

		}else if (data === 'hello.eva5-app' || data === 'hello.eva52'){		//hello from eva-desktop react-native app
			global.isEva50 = true;
			console.log('admin-app: send hello.eva5-app to iframe ...');
// alert(data + ' ' + global.isEva50);
			// this.iframe.contentWindow.postMessage('hello.eva5-app', '*');	//route to shelf-app
			if (global.page && global.page.iframe){
				global.page.iframe.contentWindow.postMessage('hello.eva5-app', '*');	//route to shelf-app
			}

		}else if (data === 'shelf_screencapture'){							//request from web-shelf
// alert(data + ' ' + global.isEva50 + ' ' + (window.ReactNativeWebView? 'rnwv' : 'not-rnwv!'));
			if (global.isEva50 && window.ReactNativeWebView){
				window.ReactNativeWebView.postMessage('screencapture');
			}else{
				window.parent.postMessage('screencapture', '*');		//android, ios, win, to parent iframe
			}

		}else if (typeof data === 'string' && data.startsWith('http')){
// alert(data + ' ' + global.isEva50 + ' ' + (window.ReactNativeWebView? 'rnwv' : 'not-rnwv!'));
			if (global.isEva50 && window.ReactNativeWebView){
				window.ReactNativeWebView.postMessage('link:' + data);
			}else{
				window.parent.postMessage('link:' + data, '*');		//android, ios, win, to parent iframe
			}

		}else if (data.loadingLink){
			global.info.log.push('> loadingLing');
			// this.setState({pdf:true, pdfLoaded:false});
			if (global.page) global.page.setState({pdf:true, pdfLoaded:false});

		}else if (data.loadLink){
			let link = data.loadLink;
			global.info.log.push('> loadLink: ' + link);
			console.log('*** load external link:', link);

			let pdf = link;		// + '&t=' + t;
			// this.setState({pdf})
			if (global.page) global.page.setState({pdf});

		}else if (data.screenshot){		//screenshot event from react-nativ main-app
			global.info.log.push('> screenshot');
			console.log('screenshot data detected! send to client ...');
			// this.setState({screen:data.screenshot});
			// this.iframe.contentWindow.postMessage(data, '*');
			if (global.page && global.page.iframe){
				// global.page.iframe.contentWindow.postMessage(data, '*');	//route to shelf-app
				// global.page.iframe.contentWindow.postMessage(data, '*');	//route to shelf-app
				global.page.iframe.contentWindow.postMessage(JSON.stringify({'app_screenshot':data.screenshot}));
			}

		}else if (typeof data === 'string'){
			try {
				data = JSON.parse(data);
				if (!data) return;

				if (data.screenshot){
					global.info.log.push('> screenshot');
					console.log('screenshot data detected! send to client ...');
					if (global.page && global.page.iframe){
						global.page.iframe.contentWindow.postMessage(JSON.stringify({'app_screenshot':data.screenshot}));
					}
				}

			} catch (error) {
				global.info.log.push('> json parse error');
			}
		}
	}

// encodeURI(str){
// 	return str.replace(/[!'()* ]/g, c => {
// 		return '%' + c.charCodeAt(0).toString(16);
// 	});
// }
	
	async onLogon(user){
		console.log('success:', user);
		global.user = global.info.user = user;

		let merchant_id = user.merchant_id || 1;

// let test = 'Christoph_M%C3%BCllner-36'
// console.log('-------------------test:', this.encodeURI(test));
// console.log('-------------------test:', encodeURI(test));
// console.log('-------------------test:', decodeURI(test));
// global.urlVars.deviceName = 'Christoph_M%C3%BCllner-36';

		let deviceId = global.urlVars.deviceName?
			// global.urlVars.deviceName + '-' + merchant_id
			// encodeURI(global.urlVars.deviceName) + '-' + merchant_id
			global.urlVars.deviceName + '-' + merchant_id
			: global.urlVars.deviceId || global.profile.deviceId || 'browser';

		deviceId = decodeURI(deviceId);
		deviceId = deviceId.replace(/ /g, '_');

		let deviceType = global.urlVars.deviceType || global.profile.deviceType || 'web';

		//fixing deviceType
		// if (global.urlVars.version && global.urlVars.version.startsWith('win-app')) deviceType = 'web';
		if (deviceType === 'tablet') deviceType = 'mobile';

		global.deviceType = deviceType;

//shelf list: https://eva50.serviceandmore.at/bl/public/api/v1/shelf
// deviceId = 'jt.005';		//for testing only

		console.log('---current device-id:', deviceId, '| deviceType:', deviceType);

		let headers = {
			'content-type'	: 'application/json; charset=utf-8',
		}
		let url, data, res, body;

		let shelfData = localStorage.getItem('shelf');
		let shelf = (shelfData !== undefined && shelfData !== 'undefined')? JSON.parse(shelfData) : null;

		let version = 'eva-web:' + global.config.version;
		if (global.urlVars.version) version += '|' + global.urlVars.version;

		global.version = version;

		global.info.version = version;
		global.info.deviceId = deviceId;
		global.info.deviceType = deviceType;
// alert('shelf: ' + JSON.stringify(shelf));

		if (shelf && shelf.box_id === deviceId){		//local shelf data exists
			console.log('#--local shelf data loaded:', shelf);

			//update existing shelf data
			body = JSON.stringify({
				merchant_id,
				heartbeat	: Math.floor(new Date().getTime() / 1000),
				data 		: {},
				online		: true,
				version,
				// 'session_count'	: 1,
			})

			url = global.profile.api + 'shelf/' + shelf.id;

			data = null;
// alert('send: ' + url + '\n' + body);

			res = await fetch(url, {method:'PUT', headers, body}).catch(err => err);
			if (res.ok){
				data = await res.json().catch(err => err);
			}else{
				// return this.setState({message:'error on updating shelf data! \n' + url + '\n' + res.status + ' ' + res.statusText});
				console.log('error on updating shelf data! \n' + url + '\n' + res.status + ' ' + res.statusText);
			}

// alert('data: ' + JSON.stringify(data));

			if (!data || data instanceof Error){
				console.log('#--error on update shelf data!');
				// return this.setState({message:'error on updating shelf data! \n' + url + '\n' + data});
			}else{
				console.log('#--shelf data updated:', data);
			}
/*
			let data = await ( await fetch(url, {method:'PUT', headers, body}) ).catch(err => {return err});

			if (data instanceof Error){
				return this.setState({message:'error on loading shelf data! \n' + url + '\n' + data});
			}else{
				console.log('#--shelf data updated:', data);
			}
*/
		}else{			//get the shelf for this device
			url = global.profile.api + 'shelf?filters[box_id]=' + deviceId;

// alert('load shelf data from api: ' + url);

			console.log('#--load shelf data from api:', url);
			res = await fetch(url).catch().catch(err => err);
			if (res.ok) shelf = await res.json().catch(err => err);

// alert('shelf data from api: ' + JSON.stringify(shelf));

			if (shelf && shelf[0]){		//shelf exists
				shelf = shelf[0];

			}else{		//this device is still not registered
				body = JSON.stringify({
					merchant_id,
					heartbeat	: Math.floor(new Date().getTime() / 1000),
					box_id		: deviceId,
					device_type	: deviceType,
					data 		: {},
					online		: true,
					version,
					// 'session_count'	: 1,
				})

				url = global.profile.api + 'shelf';
				// data = await ( await fetch(url, {method:'POST', headers, body}) ).json();

				console.log('send post to', url, body, '...');
				data = null;

// alert('create device: ' + url + '\n' + body);

				res = await fetch(url, {method:'POST', headers, body});
				if (!res.ok){
					let error = 'error on register new device for:\n'
						+ deviceType + ' box_id: ' + deviceId + '\n'
						+ url + '\n' + res.status + ' ' + res.statusText
						+ '\n' + body;

// alert('error-1: ' + error);

					return this.setState({error});
				}
				
				data = await res.json();
				if (data && data.id){
					shelf = data;

// alert('shelf-data: ' + JSON.stringify(shelf));

				}else{
					// return alert('error on loading shelf data! \n' + url);
					let error = 'error on register data for new device:\n'
						+ deviceType + ' box_id: ' + deviceId + '\n' + url + '\n' + String(data);

// alert('error-2: ' + error);

					return this.setState({error});
				}
			}

			localStorage.setItem('shelf', JSON.stringify(shelf));	//cache new shelf data
			console.log('#--shelf data successfully loaded:', shelf);
		}

		global.info.shelf = shelf;
		
		// global.info = {
		// 	mode		: global.mode,
		// 	location	: window.location,
		// 	deviceId	: deviceId,
		// 	deviceType	: deviceType,
		// 	urlVars		: global.urlVars,
		// 	config		: global.config,
		// 	profile		: global.profile,
		// 	user		: global.user,
		// 	shelf		: shelf,
		// 	log			: [],
		// }

// alert('complete!');

		// this.setState({loaded:true, loggedin:user? true: false, shelf});
		this.setState({loaded:true, loggedin:true, shelf});
	}


	onLoadPage(){
		setTimeout(e => {
			console.log('!!! restore cached user data');
			localStorage.setItem(global.profile.app + '.user', JSON.stringify(global.user));
			localStorage.setItem('shelf', JSON.stringify(global.shelf));	//cache shelf data

			global.setOrientation();

		}, 3000);
	}


	onStartTimeout(){
		this.timeout = setTimeout(e => {
			window.location = window.location.origin + window.location.pathname + '?' + new Date().getTime();
		}, 1000)
	}

	render(){
		let p = this.props;
		let s = this.state;

		if (!s.loaded) return <div className='loading'>
			{s.message}
			<div className='app-version'>{s.version}</div>
		</div>

		if (s.error){
			return <div className='app-error'>
				{s.error}
				<div className='app-version'>{s.version}</div>
			</div>
		}

		return (
			<div id='app' className='app' >
				<div className='app-info-button'
					onClick={e => {
						this.setState({showInfo:true});
					}}

					onTouchStart={this.onStartTimeout.bind(this)}
					onTouchEnd={e => clearTimeout(this.timeout)}

					onMouseDown={e => {
						this.timeout = setTimeout(e => {
							window.location = window.location.origin + window.location.pathname + '?' + new Date().getTime();
						}, 1000)
					}}
					onMouseUp={e => {
						clearTimeout(this.timeout);
					}}
				/>

				{s.loggedin?
					<div className='wrapper'>
						<AppPage
							reset={s.reset}
							onLoad={this.onLoadPage.bind(this)}
						/>
					</div>
					:
					<LoginForm onLogon={data => this.onLogon(data)}
						onCancel={e => {
							console.log('canceled!');
							if (global.user.id){
								this.setState({loggedin:true});
							}
						}}
					/>
				}
				{s.showInfo &&
					<div className='info-screen' onClick={e => this.setState({showInfo:false})}>
						<ObjectView data={global.info} />
						<div className='debug-button-bar'>
							<div onClick={e => {
									e.preventDefault();
									e.stopPropagation();
									switchFullscreen();
								}}
							>SWITCH FULLSCREEN</div>
							<div onClick={e => {
								// window.location = 'https://eva50.serviceandmore.at/app.dev/'
								window.location = global.config.debug.admin;
							}}
							>RESTART DEV</div>
						</div>
					</div>
				}
			</div>
		)
	}


/*
	//------------------------------------------------------------------------- initScrollLock
	// set for inline definition:
	// <div data-scroll={'0'} /> | <div data-scroll={'1'} />
	initScrollLock(){
		this.active = false;

		this.lockList = {
			'login-view':'1',
			'table-responsive':'1'
		}
	
		let root = document.getElementById('root');

		root.addEventListener('touchstart', e => {
			let t = e.target;
			let c = this.checkLockList(t);
			while(c === null && t.parentElement && t.id !== 'root' && t.dataset.scroll === undefined){
				t = t.parentElement;
				c = this.checkLockList(t);
			}
			this.active = (t.dataset.scroll === '1' || c === '1');
		}, {passive: true})

		root.addEventListener('touchmove', e => {
			if (!this.active) return e.preventDefault();
		})
	}
*/

	//------------------------------------------------------------------------- checkLockList
	checkLockList(t){
		if (!t) return null;
		let sl = this.lockList
		if (sl[t.id] !== undefined) return sl[t.id];

		let cl = t.classList;
		let l = cl.length;
		for (let i=0; i<l; i++){
			if (sl[cl[i]] !== undefined) return sl[cl[i]];
		}
		return null;
	}
}


const ObjectView = e => {
	let str = JSON.stringify(e.data, undefined, 4);

	return (
		<textarea className='object-view' disabled defaultValue={str} />
	)
}


const switchFullscreen = (elem=document.documentElement) => {
	// let elem = document.documentElement;
	let isOn = document.fullscreenElement !== null;

	if(document.querySelector("#root").requestFullscreen)
	document.querySelector("#root").requestFullscreen();
else if(document.querySelector("#root").webkitRequestFullScreen)
	document.querySelector("#root").webkitRequestFullScreen();

return;

	// let on = !isOn;
	// if (on !== undefined && ((on && isOn) || (!on && !isOn))) return;

/*
	if (isOn){
		if (document.exitFullscreen) document.exitFullscreen();
		else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
		else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
		else if (document.msExitFullscreen) document.msExitFullscreen();

	}else{
		if (elem.requestFullscreen)	elem.requestFullscreen();
		else if (elem.mozRequestFullScreen) elem.mozRequestFullScreen();		// Firefox
		else if (elem.webkitRequestFullscreen) elem.webkitRequestFullscreen();	// Chrome, Safari & Opera
		else if (elem.msRequestFullscreen) elem.msRequestFullscreen();			// IE/Edge
	}
*/
}
